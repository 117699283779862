import http from "../http-common";

class ClientsDataService {
  getAll() {
    return http.get("/clients");
  }

  get(id) {
    return http.get(`/clients/${id}`);
  }

  create(data) {
    return http.post("/clients", data);
  }

  update(id, data) {
    return http.put(`/clients/${id}`, data);
  }

  delete(id) {
    return http.delete(`/clients/${id}`);
  }

  findByTitle(title) {
    return http.get(`/clients?title=${title}`);
  }

  login(username, password) {
    return http.post(`/clients/login`, { username, password });
  }
}

export default new ClientsDataService();
