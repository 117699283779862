import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_test_51IP45QHmKoM90dWU15cj4OjhAoMgFbdrWElNNObYrHHxYUY9XonNa0fdkOl0RQRJxldRf3DUpojvamzUH3bSN3OL00sAPzWaeW"
);

const Modal = ({
  handleClose,
  show,
  children,
  toPay,
  id,
  payment_id,
  split_method,
}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const [clientSecret, setClientSecret] = useState("");

  toPay = (toPay.toFixed(2) * 100).toFixed(0);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: [{ id: "xl-tshirt", amountz: toPay }],
      }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <button className="CloseButton" type="button" onClick={handleClose}>
          <img src="/assets/images/back.png" alt="back" className="back-icon" />
          back
        </button>
        {children}
        <div className="App">
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                toPay={toPay}
                paymentId={payment_id}
                orderId={id}
                split_method={split_method}
              />
            </Elements>
          )}
        </div>
      </section>
    </div>
  );
};
export default Modal;
