import React, { useState, useEffect } from "react";
import OrderDataService from "../services/order.service";
import ClientsDataService from "../services/client.service";
import DashboardLayout from "./dashboard/DashboardLayout";

const ClientDashboard = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Check for existing session
    const authToken = localStorage.getItem("authToken");
    const savedClientId = localStorage.getItem("clientId");
    if (authToken && savedClientId) {
      setIsAuthenticated(true);
      setClientId(savedClientId);
      fetchClientData(savedClientId);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const response = await ClientsDataService.login(username, password);
      if (response.data.success) {
        setIsAuthenticated(true);
        setClientId(response.data.clientId);
        // Save session data
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("clientId", response.data.clientId);
        fetchClientData(response.data.clientId);
      } else {
        setError("Invalid credentials");
      }
    } catch (error) {
      setError("An error occurred during login. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClientData = async (id) => {
    try {
      const response = await ClientsDataService.get(id);
      setClientData(response.data);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setClientId(null);
    setClientData(null);
    setOrders([]);
  };

  useEffect(() => {
    if (isAuthenticated && clientId) {
      fetchOrders(clientId);
    }
  }, [isAuthenticated, clientId]);

  const fetchOrders = async (clientId) => {
    try {
      const response = await OrderDataService.findByClientId(clientId);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  if (isAuthenticated) {
    return (
      <DashboardLayout
        clientData={clientData}
        orders={orders}
        onLogout={handleLogout}
      />
    );
  }

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-title">Admin Login</h2>
        {error && <div className="login-error">{error}</div>}
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          <button type="submit" className="login-button" disabled={isLoading}>
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ClientDashboard;
