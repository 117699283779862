import axios from "axios";

const isDevelopment = window.location.hostname === "localhost";
const baseURL = isDevelopment
  ? "http://localhost:443/api"
  : "https://finally.irco.gr:443/api";

export default axios.create({
  // THIS IS THE ONLY PORT CHANGE NEEDED FOR DEPLOYMENT
  //baseURL: "https://finally.irco.gr:443/api",
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
  },
});
