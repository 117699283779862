import React, { Component } from "react";
import { withRouter } from "../../common/with-router";

class AddTip extends Component {
  constructor(props) {
    super(props);

    this.addtip = this.addtip.bind(this);
    this.handleCustomAmount = this.handleCustomAmount.bind(this);

    this.state = {
      tip: 0,
      tip_percentage: 0,
      message: "",
      orderId: "",
      total: 0,
      customAmount: 0,
      active: "notip",
    };
  }

  componentDidMount() {
    const orderId = this.props.oID;
    this.setState({ orderId });
    this.state.total = this.props.y;
  }

  addtip(tip_perc) {
    this.state.total = this.props.y + (this.props.y * tip_perc) / 100;
    this.state.tip = (this.props.y * tip_perc) / 100;
    this.props.MasterTotal2(this.state.total);
    this.props.TheTip(this.state.tip);
    this.setState({
      tip_percentage: tip_perc,
    });
  }

  handleCustomAmount(value) {
    this.state.total = this.props.y + value;
    this.props.MasterTotal2(this.state.total);
    this.props.theTip(value);
    this.setState({
      tip: this.state.value,
    });
  }

  someFunct(name) {
    this.setState({ active: name });
  }

  render() {
    const { tipPercentages } = this.props;

    return (
      <div className="tipz">
        <button
          value="0"
          className={
            this.state.active === "notip" ? "tip noTip active" : "tip noTip"
          }
          onClick={() => this.someFunct("notip") + this.addtip(0)}
        >
          No Tip
        </button>
        <button
          value={tipPercentages.tip1}
          className={
            this.state.active === "first"
              ? "tip btn btn-sm active"
              : "tip btn btn-sm"
          }
          onClick={() =>
            this.someFunct("first") + this.addtip(tipPercentages.tip1)
          }
        >
          {tipPercentages.tip1}%
          <div>
            €{parseFloat((this.props.y * tipPercentages.tip1) / 100).toFixed(2)}
          </div>
        </button>
        <button
          value={tipPercentages.tip2}
          className={
            this.state.active === "second"
              ? "tip btn btn-sm active"
              : "tip btn btn-sm"
          }
          onClick={() =>
            this.someFunct("second") + this.addtip(tipPercentages.tip2)
          }
        >
          {tipPercentages.tip2}%
          <div>
            €{parseFloat((this.props.y * tipPercentages.tip2) / 100).toFixed(2)}
          </div>
        </button>
        <button
          value={tipPercentages.tip3}
          className={
            this.state.active === "third"
              ? "tip btn btn-sm active"
              : "tip btn btn-sm"
          }
          onClick={() =>
            this.someFunct("third") + this.addtip(tipPercentages.tip3)
          }
        >
          {tipPercentages.tip3}%
          <div>
            €{parseFloat((this.props.y * tipPercentages.tip3) / 100).toFixed(2)}
          </div>
        </button>
        <form
          className={
            this.state.active === "fourth" ? " tipform active" : " tipform"
          }
          onClick={() => this.someFunct("fourth")}
          noValidate
          autoComplete="off"
        >
          <input
            type="text"
            inputMode="decimal"
            placeholder="Other"
            name="CustomAmount"
            //min="0"
            onChange={(e) => {
              let value =
                e.target.value.replace("-", "") &&
                e.target.value.replace(",", "."); // Replace comma with dot

              // Add zero before dot if it's the first character
              if (value.startsWith(".")) {
                value = "0" + value;
                e.target.value = value; // Update the input field value
              }

              // Validate and restrict to two decimal places
              const regex = /^\d+(\.\d{0,2})?$/;
              if (regex.test(value)) {
                const numericValue = parseFloat(value); // Convert to number
                console.log("Input value:", numericValue); // Log the numeric value
                this.handleCustomAmount(numericValue ? numericValue : 0);
              } else {
                // If the value doesn't match the regex, truncate to two decimal places
                value = value.slice(0, value.indexOf(".") + 3);
                e.target.value = value; // Update the input field value
                const numericValue = parseFloat(value); // Convert to number
                this.handleCustomAmount(numericValue ? numericValue : 0);
              }
            }}
          />
          <span className="euroInInputSmall">€</span>
        </form>
      </div>
    );
  }
}

export default withRouter(AddTip);
