import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Place from "./components/Place";
import Items from "./components/Items";
import Page404 from "./components/Page404";
import Payment from "./components/payment/payment";
import ClientDashboard from "./components/ClientDashboard";
import PaymentStatus from "./components/payment/stripe/PaymentStatus";
import OrderDataService from "./services/order.service";
import TableDataService from "./services/table.service";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import axios from "axios";

const gitHubUrl = "http://demo.oncloud.gr/s1services";

const stripePromise = loadStripe(
  "pk_test_51IP45QHmKoM90dWU15cj4OjhAoMgFbdrWElNNObYrHHxYUY9XonNa0fdkOl0RQRJxldRf3DUpojvamzUH3bSN3OL00sAPzWaeW"
);

const path1 = window.location.pathname.split("/")[1];
const path2 = window.location.pathname.split("/")[2];

function App() {
  const [userData, setUserData] = useState();
  const [theURL, setTheURL] = useState();
  const [oID, setOID] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Only process if the path actually needs fixing
    if (location.pathname.includes("//")) {
      const fixedPath = location.pathname.replace(/\/{2,}/g, "/");
      navigate(fixedPath, { replace: true });
      return;
    }

    /*  getGiHubUserWithAxios(); */
    getOrderFromTable(path1, path2);
  }, [location, navigate]);

  /*   useEffect(() => {
    /*  getGiHubUserWithAxios(); 
    getOrderFromTable(path1, path2);
  }, []); */

  const getGiHubUserWithAxios = async () => {
    //  const response = await axios.get(gitHubUrl);
    /*    var data = {
      service: "login",
      username: "john",
      password: "aitis",
      appId: "2001",
      COMPANY: "1000",
      BRANCH: "1000",
      MODULE: "0",
      REFID: "1",
    }; */
    const response = await axios.post(gitHubUrl /* , data */);
    setUserData(response.data);
    console.log(response.data);
  };

  const getOrderFromTable = (path1, path2) => {
    if (path1 === "o") {
      // Direct order access
      OrderDataService.get(path2)
        .then((response) => {
          const table1 = response.data;
          if (!table1 || !table1.id) {
            setOID("0");
            setTheURL("404");
          } else {
            const theURL = `/o/${path2}`;
            setOID(path2);
            setTheURL(theURL);
            // navigate(theURL, { replace: true });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (path1 === "t") {
      // Table access - first check if table exists
      TableDataService.get(path2)
        .then((response) => {
          const table = response.data;
          if (!table || !table.id) {
            setOID("0");
            setTheURL("404");
          }

          // Table exists, check for active order
          OrderDataService.getOrderFromTable(path2)
            .then((response) => {
              const orders = response.data;

              if (orders && orders.length > 0) {
                // Active order exists
                const order = orders[0];
                const orderURL = `/o/${order.id}`;
                setOID(order.id);
                setTheURL(orderURL);
              } else {
                // No active order - create new one
                const data = {
                  table_number: path2,
                  client_id: table.clientId,
                };

                OrderDataService.create(data)
                  .then((response) => {
                    const createdOrder = response.data;
                    console.log("Created new order:", createdOrder);
                    const newOrderURL = `/o/${createdOrder.id}`;
                    setOID(createdOrder.id);
                    setTheURL(newOrderURL);
                    navigate(newOrderURL, { replace: true });
                  })
                  .catch((error) => {
                    console.error("Error creating order:", error);
                    setOID("0");
                    setTheURL("error");
                  });
              }
            })
            .catch((error) => {
              console.error("Error fetching order:", error);
              setOID("0");
              setTheURL("error");
            });
        })
        .catch((error) => {
          console.error("Error fetching table:", error);
          setOID("0");
          setTheURL("404");
        });
    } else {
      // Invalid path
      setOID("0");
      setTheURL("404");
    }
  };

  if (
    oID &&
    oID !== 0 &&
    theURL !== "404" &&
    theURL !== "error" &&
    window.location.pathname.split("/")[3] !== "completion"
  ) {
    return (
      <div className="container">
        <div className="App"></div>
        <Routes>
          <Route
            path="/t/*"
            element={
              <>
                <Navigate replace to={theURL} />
              </>
            }
          />
        </Routes>
        <Place oID={oID} />
        <Items oID={oID} />
        <Payment oID={oID} />
      </div>
    );
  } else if (window.location.pathname.split("/")[3] == "completion") {
    return (
      <div className="container mt-5">
        <Place oID={oID} />
        <Elements stripe={stripePromise}>
          <PaymentStatus />
        </Elements>
      </div>
    );
  } else if (window.location.pathname.split("/")[1] == "login") {
    return <ClientDashboard oID={oID} />;
  } else {
    return (
      <div className="container mt-5">
        <Routes>
          <Route
            path="/*"
            element={<>{/* <Navigate replace to="404" /> */}</>}
          />
        </Routes>
        <Page404 />
      </div>
    );
  }
}

export default App;
