import React, { Component } from "react";
import { withRouter } from "../../../common/with-router";
import PaymentButton from "../items/PaymentButton.component";

class CustomAmount extends Component {
  constructor(props) {
    super(props);

    this.handleCustomAmount = this.handleCustomAmount.bind(this);

    this.state = {
      MasterTotal: 0,
      show: false,
    };
  }

  handleCustomAmount(value) {
    this.setState({
      MasterTotal: value,
    });
  }

  render() {
    return (
      <div className="customAmount">
        Choose your amount:
        <form className="form2" noValidate autoComplete="off">
          <input
            type="text"
            inputMode="decimal"
            className="customAmountField"
            placeholder="Type amount"
            name="CustomAmount"
            min="0"
            max={this.props.MasterTotal}
            onChange={(e) => {
              let value =
                e.target.value.replace("-", "") &&
                e.target.value.replace(",", "."); // Replace comma with dot

              // Add zero before dot if it's the first character
              if (value.startsWith(".")) {
                value = "0" + value;
                e.target.value = value; // Update the input field value
              }

              // Validate and restrict to two decimal places
              const regex = /^\d+(\.\d{0,2})?$/;
              if (regex.test(value)) {
                const numericValue = parseFloat(value); // Convert to number
                console.log("Input value:", numericValue); // Log the numeric value
                this.handleCustomAmount(numericValue ? numericValue : 0);
              } else {
                // If the value doesn't match the regex, truncate to two decimal places
                value = value.slice(0, value.indexOf(".") + 3);
                e.target.value = value; // Update the input field value
                const numericValue = parseFloat(value); // Convert to number
                this.handleCustomAmount(numericValue ? numericValue : 0);
              }
            }}
          />
          <span className="euroInInput">€</span>
        </form>
        {this.state.MasterTotal !== 0 ? (
          <PaymentButton
            MasterTotal={this.state.MasterTotal}
            SplitMethod="CustomAmount"
            billTotal={this.props.billTotal}
            remainingAmount={this.props.remainingAmount}
            tip={this.props.tip}
          ></PaymentButton>
        ) : (
          <button className="m-3 btnpay btn-sm" disabled>
            Pay €{this.state.MasterTotal.toFixed(2)}
          </button>
        )}
      </div>
    );
  }
}

export default withRouter(CustomAmount);
