import React, { useState, useEffect } from "react";
import PaymentDataService from "../../services/payment.service";
import OrderItemDataService from "../../services/orderitem.service";
import Counter from "./split/Counter.component";
import CustomAmount from "./split/CustomAmount.component";
import AddTip from "./AddTip.component";
import ChooseThings2 from "./split/ChooseThings2.component";
import PaymentButton from "./items/PaymentButton.component";
import ClientsDataService from "../../services/client.service";
import OrderDataService from "../../services/order.service";

const ItemsList2 = ({ oID, clientData }) => {
  const [showPayFullBtn, setShowPayFullBtn] = useState(true);
  const [showSplitBtn, setShowSplitBtn] = useState(true);
  const [SumAm, setSumAm] = useState(0);
  const [SumPr, setSumPr] = useState(0);
  const [theTotal, setTheTotal] = useState(0);
  const [theTip, setTheTip] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [clicked2, setClicked2] = useState("none");
  const [clickedPayFull, setClickedPayFull] = useState(false);
  const [doNotShowBackButton, setDoNotShowBackButton] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [showToPay, setShowToPay] = useState(false);

  // Add new state for client data
  const [tipPercentages, setTipPercentages] = useState({
    tip1: 5,
    tip2: 10,
    tip3: 15,
  });

  useEffect(() => {
    OrderItemDataService.sumPrice(oID)
      .then((response) => {
        var a = response.data;
        var b = a.data;
        setSumPr(b);

        PaymentDataService.sumPaid(oID)
          .then((response) => {
            var c = response.data;
            var d = c.data;
            setSumAm(d);
            setRemainingAmount(b - d);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });

    // Get client ID from order ID
    if (oID) {
      OrderDataService.get(oID)
        .then((response) => {
          const clientId = response.data.client_id;
          // Fetch client data
          ClientsDataService.get(clientId)
            .then((response) => {
              const client = response.data;
              setTipPercentages({
                tip1: client.tip1 || 5,
                tip2: client.tip2 || 10,
                tip3: client.tip3 || 15,
              });
            })
            .catch((error) => {
              console.error("Error fetching client data:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching order data:", error);
        });
    }
  }, [oID]);

  const handleClick = () => {
    setClicked(true);
    setShowPayFullBtn(false);
  };

  const handleClickPayFull = () => {
    setTheTotal(remainingAmount);
    setClickedPayFull(true);
    setShowSplitBtn(false);
  };

  const handleClick2 = (clicked2a) => {
    setClicked2(clicked2a);
    if (clicked2a === "divide") {
      setShowToPay(true);
      setTheTotal(remainingAmount);
    } else if (clicked2a === "choose") {
      setShowToPay(true);
      // Add overlay when "choose" is selected
      const itemsList = document.querySelector(".list-group");
      if (itemsList) {
        itemsList.classList.add("disabled");
        const overlay = document.createElement("div");
        overlay.className = "items-overlay";
        document.body.appendChild(overlay);
      }
    } else {
      setShowToPay(false);
      // Remove overlay when going back
      const itemsList = document.querySelector(".list-group");
      const overlay = document.querySelector(".items-overlay");
      if (itemsList) {
        itemsList.classList.remove("disabled");
      }
      if (overlay) {
        overlay.remove();
      }
    }
  };

  const back = () => {
    setClicked(false);
    setShowPayFullBtn(true);
    setClickedPayFull(false);
    setClicked2("none");
    setShowSplitBtn(true);
  };

  const back2 = () => {
    setClicked2("none");
    setShowToPay(false);
    // Remove overlay when going back
    const itemsList = document.querySelector(".list-group");
    const overlay = document.querySelector(".items-overlay");
    if (itemsList) {
      itemsList.classList.remove("disabled");
    }
    if (overlay) {
      overlay.remove();
    }
  };

  const handleTotal = (langValue) => {
    setTheTotal(langValue);
  };

  const handleTip = (langValue2) => {
    setTheTip(langValue2);
  };

  const handleDoNotShowBackButton = (valueImported) => {
    setDoNotShowBackButton(valueImported);
  };

  return (
    <div className={`list row, arxikiSection3 ${clicked ? "firstpage" : ""}`}>
      <div className="totalsContainer">
        <div
          className={
            "" + (SumAm === 0 || SumAm === null ? "total" : "totalinvaded")
          }
        >
          {" "}
          Total: {parseFloat(SumPr).toFixed(2)} €
        </div>
        {SumAm === 0 || SumAm === null ? (
          <div className="nooneHasPaid"></div>
        ) : SumAm < SumPr ? (
          <div className="remaining">
            Remaining: {(SumPr - SumAm).toFixed(2)} €
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {SumAm < SumPr && SumPr ? (
        <div className="col-md-5">
          {showPayFullBtn && !clickedPayFull ? (
            <div>
              <button
                className="m-3 btnpay1stpage btn-sm"
                onClick={handleClickPayFull}
              >
                PAY IN FULL
              </button>
            </div>
          ) : showPayFullBtn && clickedPayFull ? (
            <div>
              <button className="m-3 btnbk btn-sm clearhere" onClick={back}>
                <img
                  src="/assets/images/back.png"
                  alt="back"
                  className="back-icon"
                />
                back
              </button>{" "}
              <div className="tipTitle">Add Tip?</div>
              <AddTip
                MasterTotal2={handleTotal}
                TheTip={handleTip}
                y={remainingAmount}
                oID={oID}
                tipPercentages={tipPercentages}
              ></AddTip>
              <PaymentButton
                MasterTotal={theTotal}
                SplitMethod="PayInFull"
                billTotal={theTotal}
                remainingAmount={remainingAmount}
                oID={oID}
                msg=""
                tip={theTip}
              ></PaymentButton>
            </div>
          ) : (
            <div></div>
          )}
          {clicked ? (
            <div>
              {/* ########## DIVIDE ############ */}
              {clicked2 === "divide" ? (
                <div>
                  <div>
                    {!doNotShowBackButton ? (
                      <button
                        className="m-3 btnbk btn-sm clearhere"
                        onClick={back2}
                      >
                        <img
                          src="/assets/images/back.png"
                          alt="back"
                          className="back-icon"
                        />
                        back
                      </button>
                    ) : (
                      <div></div>
                    )}{" "}
                    <div id="mount-point"></div>
                    <Counter
                      DoNotShowBackButton={handleDoNotShowBackButton}
                      MasterTotal2={handleTotal}
                      TheTip={handleTip}
                      MasterTotal={theTotal}
                      billTotal={theTotal}
                      remainingAmount={remainingAmount}
                      tip={theTip}
                      oID={oID}
                      tipPercentages={tipPercentages}
                    />
                  </div>
                </div>
              ) : clicked2 === "choose" ? (
                <div>
                  <div>
                    {!doNotShowBackButton ? (
                      <button
                        className="m-3 btnbk btn-sm clearhere"
                        onClick={back2}
                      >
                        <img
                          src="/assets/images/back.png"
                          alt="back"
                          className="back-icon"
                        />
                        back
                      </button>
                    ) : (
                      <div></div>
                    )}{" "}
                    <ChooseThings2
                      DoNotShowBackButton={handleDoNotShowBackButton}
                      MasterTotal2={handleTotal}
                      TheTip={handleTip}
                      oID={oID}
                      MasterTotal={theTotal}
                      billTotal={theTotal}
                      remainingAmount={remainingAmount}
                      tip={theTip}
                      tipPercentages={tipPercentages}
                    />
                  </div>
                </div>
              ) : clicked2 === "custom" ? (
                <div>
                  {!doNotShowBackButton ? (
                    <button
                      className="m-3 btnbk btn-sm clearhere"
                      onClick={back2}
                    >
                      <img
                        src="/assets/images/back.png"
                        alt="back"
                        className="back-icon"
                      />
                      back
                    </button>
                  ) : (
                    <div></div>
                  )}{" "}
                  <CustomAmount
                    MasterTotal2={handleTotal}
                    MasterTotal={theTotal}
                    billTotal={theTotal}
                    remainingAmount={remainingAmount}
                    TheTip={handleTip}
                    tip={theTip}
                  />
                </div>
              ) : (
                <div>
                  <button className="m-3 btnbk btn-sm clearhere" onClick={back}>
                    <img
                      src="/assets/images/back.png"
                      alt="back"
                      className="back-icon"
                    />
                    back
                  </button>

                  <button
                    className="m-3 btnpay2 btn-sm "
                    onClick={() => handleClick2("divide")}
                  >
                    divide
                  </button>

                  <button
                    className="m-3 btnpay2 btn-sm "
                    onClick={() => handleClick2("choose")}
                  >
                    choose my own things
                  </button>

                  <button
                    className="m-3 btnpay2 btn-sm "
                    onClick={() => handleClick2("custom")}
                  >
                    custom amount
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div>
              {showSplitBtn ? (
                <div>
                  <button className="m-3 btnpay2 btn-sm " onClick={handleClick}>
                    SPLIT
                  </button>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
      ) : SumPr === 0 || SumPr === null ? (
        <div className="paidOrder">
          <h3>There is no order for this table</h3>
          <p>you can check the menu and order to the waiter</p>
        </div>
      ) : (
        <div className="paidOrder">
          <h3>This order has been paid!</h3>
          <p>Thanks for using Finally</p>
        </div>
      )}
    </div>
  );
};

export default ItemsList2;
