import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import PaymentDataService from "../../../services/payment.service";
import PaymentItemDataService from "../../../services/paymentitem.service";
import OrderItemDataService from "../../../services/orderitem.service";
import OrderDataService from "../../../services/order.service";

const PaymentStatus = () => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [showReturnButton, setShowReturnButton] = useState(false);
  var orderId = parseInt(window.location.pathname.split("/")[2]);

  const handleReturnToOrder = () => {
    navigate(`/o/${orderId}`);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    var paymentId = window.location.pathname.split("/")[4];
    var splitMethod = window.location.pathname.split("/")[6];

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Success! Payment received.");
          setShowReturnButton(true); // Show return button on success

          var paymentData = {
            situation: "paid",
          };

          PaymentDataService.update(paymentId, paymentData)
            .then((res) => {
              // Check total paid amount vs order total
              Promise.all([
                PaymentDataService.sumPaid(orderId),
                OrderItemDataService.sumPrice(orderId),
              ])
                .then(([paidResponse, orderItemsResponse]) => {
                  const totalPaid = paidResponse.data.data;
                  const orderTotal = orderItemsResponse.data.data;
                  console.log("Total Paid:", totalPaid);
                  console.log("Order Items Total:", orderTotal);

                  if (totalPaid >= orderTotal) {
                    // Update order bill_paid status to true (using true instead of 1)
                    OrderDataService.updateBillPaid(orderId, {
                      bill_paid: true,
                    })
                      .then(() => {
                        console.log("Order marked as fully paid");
                      })
                      .catch((e) => {
                        console.log("Error updating order paid status:", e);
                      });
                  }
                })
                .catch((e) => {
                  console.log("Error checking payment totals:", e);
                });
            })
            .catch((e) => {
              console.log(e);
            });

          if (splitMethod == "ChooseYourItems") {
            var paymentData2 = {
              paid: "paid",
            };
            PaymentItemDataService.update(paymentId, paymentData2)
              .then((res) => {
                PaymentItemDataService.findByPaid(paymentId)
                  .then((res) => {
                    OrderItemDataService.updatePaid(paymentId, paymentData2)
                      .then((res) => {
                        console.log(res.data);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          }

          break;

        case "processing":
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Payment failed. Please try another payment method.");
          break;

        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe, orderId]);

  return (
    <div className="successPayment">
      <div>{message}</div>
      {showReturnButton && (
        <button className="return-button" onClick={handleReturnToOrder}>
          Return to Order
        </button>
      )}
    </div>
  );
};

export default PaymentStatus;
