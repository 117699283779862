import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PaymentDataService from "../../services/payment.service";
import OrderItemDataService from "../../services/orderitem.service";
import ClientsDataService from "../../services/client.service";

const DashboardLayout = ({ clientData, orders, onLogout }) => {
  const [activeTab, setActiveTab] = useState("orders");
  const [orderTotals, setOrderTotals] = useState({});
  const [paidTotals, setPaidTotals] = useState({});
  const [timeFilter, setTimeFilter] = useState("all");
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [tipPercentages, setTipPercentages] = useState({
    tip1: 5,
    tip2: 10,
    tip3: 15,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("");
  const [periodTotal, setPeriodTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch client data including tip percentages
    if (clientData?.id) {
      ClientsDataService.get(clientData.id)
        .then((response) => {
          const client = response.data;
          setTipPercentages({
            tip1: client.tip1 || 5,
            tip2: client.tip2 || 10,
            tip3: client.tip3 || 15,
          });
        })
        .catch((error) => {
          console.error("Error fetching the client data:", error);
        });
    }
  }, [clientData?.id]);

  useEffect(() => {
    // Fetch totals for all orders
    const fetchTotals = async () => {
      try {
        const totalsPromises = orders.map((order) =>
          Promise.all([
            OrderItemDataService.sumPrice(order.id),
            PaymentDataService.sumPaid(order.id),
          ])
        );

        const results = await Promise.all(totalsPromises);

        const newOrderTotals = {};
        const newPaidTotals = {};

        results.forEach((result, index) => {
          const orderId = orders[index].id;
          newOrderTotals[orderId] = result[0].data.data;
          newPaidTotals[orderId] = result[1].data.data;
        });

        setOrderTotals(newOrderTotals);
        setPaidTotals(newPaidTotals);
      } catch (error) {
        console.error("Error fetching totals:", error);
      }
    };

    if (orders.length > 0) {
      fetchTotals();
    }
  }, [orders]);

  useEffect(() => {
    filterOrders(timeFilter);
  }, [orders, timeFilter]);

  useEffect(() => {
    // Calculate total paid amount for filtered orders
    const total = filteredOrders.reduce((sum, order) => {
      return sum + (paidTotals[order.id] || 0);
    }, 0);
    setPeriodTotal(total);
  }, [filteredOrders, paidTotals]);

  const filterOrders = (filterType) => {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);

    const lastWeek = new Date(now);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const lastMonth = new Date(now);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    let filtered;
    switch (filterType) {
      case "day":
        filtered = orders.filter(
          (order) => new Date(order.createdAt) >= yesterday
        );
        break;
      case "week":
        filtered = orders.filter(
          (order) => new Date(order.createdAt) >= lastWeek
        );
        break;
      case "month":
        filtered = orders.filter(
          (order) => new Date(order.createdAt) >= lastMonth
        );
        break;
      default:
        filtered = orders;
    }
    setFilteredOrders(filtered);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("clientId");
    onLogout();
    navigate("/login");
  };

  // Use the same logo path construction as Place.js
  const logoPath = clientData?.logo
    ? `../../assets/images/logos/${clientData.logo}`
    : null;

  const handleTipChange = (field, value) => {
    // Ensure value is a number and between 0 and 100
    const numValue = Math.min(Math.max(Number(value) || 0, 0), 100);
    setTipPercentages((prev) => ({
      ...prev,
      [field]: numValue,
    }));
  };

  const handleSaveSettings = async () => {
    try {
      await ClientsDataService.update(clientData.id, {
        tip1: tipPercentages.tip1,
        tip2: tipPercentages.tip2,
        tip3: tipPercentages.tip3,
      });
      setUpdateMessage("Tip percentages updated successfully!");
      setIsEditing(false);
      setTimeout(() => setUpdateMessage(""), 3000);
    } catch (error) {
      setUpdateMessage("Error updating tip percentages. Please try again.");
      console.error("Error updating settings:", error);
    }
  };

  const getActivePosition = () => {
    switch (timeFilter) {
      case "day":
        return "translateX(100%)";
      case "week":
        return "translateX(200%)";
      case "month":
        return "translateX(300%)";
      default:
        return "translateX(0)";
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-sidebar">
        <div className="sidebar-header">
          {logoPath && (
            <img src={logoPath} alt="Logo" className="sidebar-logo" />
          )}
          <h3>{clientData?.name || "Admin Dashboard"}</h3>
        </div>
        <nav className="sidebar-nav">
          <button
            className={`nav-item ${activeTab === "orders" ? "active" : ""}`}
            onClick={() => setActiveTab("orders")}
          >
            <i className="fas fa-list"></i>
            Orders
          </button>
          <button
            className={`nav-item ${activeTab === "analytics" ? "active" : ""}`}
            onClick={() => setActiveTab("analytics")}
          >
            <i className="fas fa-chart-bar"></i>
            Analytics
          </button>
          <button
            className={`nav-item ${activeTab === "settings" ? "active" : ""}`}
            onClick={() => setActiveTab("settings")}
          >
            <i className="fas fa-cog"></i>
            Settings
          </button>
        </nav>
        <button className="logout-button" onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i>
          Logout
        </button>
      </div>

      <div className="dashboard-main">
        <div className="dashboard-header">
          <div className="header-content">
            <h2>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
            {activeTab === "orders" && (
              <>
                <div className="filter-section">
                  <div
                    className="active-background"
                    style={{ transform: getActivePosition() }}
                  />
                  <button
                    className={`filter-button ${
                      timeFilter === "all" ? "active" : ""
                    }`}
                    onClick={() => setTimeFilter("all")}
                  >
                    All Time
                  </button>
                  <button
                    className={`filter-button ${
                      timeFilter === "day" ? "active" : ""
                    }`}
                    onClick={() => setTimeFilter("day")}
                  >
                    Last 24h
                  </button>
                  <button
                    className={`filter-button ${
                      timeFilter === "week" ? "active" : ""
                    }`}
                    onClick={() => setTimeFilter("week")}
                  >
                    Week
                  </button>
                  <button
                    className={`filter-button ${
                      timeFilter === "month" ? "active" : ""
                    }`}
                    onClick={() => setTimeFilter("month")}
                  >
                    Month
                  </button>
                </div>
                <div className="period-total">
                  Period Total: €{periodTotal.toFixed(2)}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="dashboard-content">
          {activeTab === "orders" && (
            <div className="orders-grid">
              {filteredOrders.map((order) => (
                <div key={order.id} className="order-card">
                  <div className="order-header">
                    <h4>Order #{order.id}</h4>
                    <span
                      className={`status ${
                        orderTotals[order.id] <= paidTotals[order.id]
                          ? "paid"
                          : "pending"
                      }`}
                    >
                      {orderTotals[order.id] <= paidTotals[order.id]
                        ? "Paid"
                        : "Pending"}
                    </span>
                  </div>
                  <div className="order-details">
                    <p>Table: {order.table_number}</p>
                    <p>Total: €{orderTotals[order.id]?.toFixed(2) || "0.00"}</p>
                    <p>
                      Paid Amount: €{paidTotals[order.id]?.toFixed(2) || "0.00"}
                    </p>
                    <p>
                      Date: {new Date(order.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
              {filteredOrders.length === 0 && (
                <div className="no-orders">
                  <p>No orders found for the selected time period.</p>
                </div>
              )}
            </div>
          )}

          {activeTab === "analytics" && (
            <div className="analytics-placeholder">
              <h3>Analytics Coming Soon</h3>
              <p>This feature is under development</p>
            </div>
          )}

          {activeTab === "settings" && (
            <div className="settings-container">
              <div className="settings-section">
                <h3>Tip Percentages</h3>
                <p className="settings-description">
                  Set the three tip percentage options that will be shown to
                  customers.
                </p>

                {updateMessage && (
                  <div
                    className={`update-message ${
                      updateMessage.includes("Error") ? "error" : "success"
                    }`}
                  >
                    {updateMessage}
                  </div>
                )}

                <div className="tip-settings">
                  {isEditing ? (
                    <>
                      <div className="tip-input-group">
                        <label>Tip Option 1:</label>
                        <div className="percentage-input">
                          <input
                            type="number"
                            min="0"
                            max="100"
                            value={tipPercentages.tip1}
                            onChange={(e) =>
                              handleTipChange("tip1", e.target.value)
                            }
                          />
                          <span>%</span>
                        </div>
                      </div>
                      <div className="tip-input-group">
                        <label>Tip Option 2:</label>
                        <div className="percentage-input">
                          <input
                            type="number"
                            min="0"
                            max="100"
                            value={tipPercentages.tip2}
                            onChange={(e) =>
                              handleTipChange("tip2", e.target.value)
                            }
                          />
                          <span>%</span>
                        </div>
                      </div>
                      <div className="tip-input-group">
                        <label>Tip Option 3:</label>
                        <div className="percentage-input">
                          <input
                            type="number"
                            min="0"
                            max="100"
                            value={tipPercentages.tip3}
                            onChange={(e) =>
                              handleTipChange("tip3", e.target.value)
                            }
                          />
                          <span>%</span>
                        </div>
                      </div>
                      <div className="settings-actions">
                        <button
                          className="save-button"
                          onClick={handleSaveSettings}
                        >
                          Save Changes
                        </button>
                        <button
                          className="cancel-button"
                          onClick={() => setIsEditing(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="current-tips">
                        <p>
                          Current tip options: {tipPercentages.tip1}%,{" "}
                          {tipPercentages.tip2}%, {tipPercentages.tip3}%
                        </p>
                      </div>
                      <button
                        className="edit-button"
                        onClick={() => setIsEditing(true)}
                      >
                        Edit Tip Percentages
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
